import UserLogin from 'Templates/UserLogin';

const Login = () => (
  <div className="bg-gray-50 flex justify-center items-center h-screen flex-col gap-8">
    <UserLogin />
  </div>
);
export async function getServerSideProps({ req: { cookies }, query }) {
  // console.log("🚀 ~ file: login.js:9 ~ getServerSideProps ~ query", query)
  try {
    const auth = JSON.parse(cookies.AUTH);
    let queryString = '';
    Object.keys(query).forEach(queryParam => {
      queryString += `${queryParam}=${query[queryParam]}&`
    })
    if (auth && auth.token) {
      return {
        redirect: {
          destination: '/db-view/' + auth.projectId + `${queryString ? `?${queryString}` : ''}`,
          permanent: false
        }
      };
    }
  } catch (err) { }

  return {
    props: {
    }
  }
}
export default Login;
