import PropTypes from 'prop-types';
import Input from '../atoms/Input';
import Label from '../atoms/Label';

const InputWithLabel = ({ className, labelText, varient = 'normal', type, name, placeholder, handleFocus, handleBlur, classForInput, classForLabel, disabled, onChange,isRequired=false, value,...properties }) => {
  return (

    <div className={`${className}`}>
      <Label className={`text-gray-900 text-sm font-normal ${classForLabel}`}>{labelText}</Label>{isRequired && <span className='text-red-400'>*</span>}
      <Input className={`border border-gray-200 rounded-lg  w-full mt-2 ${classForInput} `} value={value} type={type} name={name} placeholder={placeholder} varient={varient} onFocus={handleFocus} onBlur={handleBlur} disabled={disabled} onChange={onChange} {...properties} />
    </div>

  )
}

InputWithLabel.propTypes = {
  varient: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  labelText: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  handleFocus: PropTypes.func,
  handleBlur: PropTypes.func,
  classForInput: PropTypes.string,
  classForLabel: PropTypes.string
}
export default InputWithLabel