import { handleApiResponse } from "@/services/helper-function.service";
import { setAuth } from "@/services/identity.service";
import {
  authenticateUser,
  resendOtp,
  signInWithEmail,
  verifyOtp
} from "@/services/user.service";
import Image from "next/image";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Anchor from "../../atoms/Anchor";
import Button from "../../atoms/Button";
import Heading from "../../atoms/Heading";
import Input from "../../atoms/Input";
import Label from "../../atoms/Label";
import Text from "../../atoms/Text";
import InputWithLabel from "../../molecules/InputWithLabel";

let timerId;
const Login = ({
  className,
  basePath = process.env.NEXT_PUBLIC_BASE_PATH,
  setShowAlert,
  setShowError,
}) => {
  const router = useRouter();

  const [hasFocus, setFocus] = useState(false);
  const [value, setValue] = useState(false);
  const [isOtpSent, setOtpSent] = useState(false);
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState(false);
  const [timerValue, setTimerValue] = useState(0);
  const [otpValue, setOtpValue] = useState("");
  const [disableVerify, setDisableVerify] = useState(true);
  const [showResend, setShowResend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [otpDisplayValue, setOtpDisplayValue] = useState("");
  const [googleRedirectUrl, setGoogleRedirectUrl] = useState(`${process.env.NEXT_PUBLIC_API_URL}/auth/google/Login`)
  const { query } = useRouter();
  const redirectUrl = query;


  useEffect(() => {
    setGoogleRedirectUrl(`${process.env.NEXT_PUBLIC_API_URL}/auth/google/Login?from=${redirectUrl.from}`)
  }, [])

  const emailValidation = () => {
    if (value) {
      if (emailRegex.test(value)) {
        setIsEmailValid(true);
        setErrorMessage("");
      } else {
        setIsEmailValid(false);
        setErrorMessage("Please enter valid email");
      }
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    emailValidation();
  }, [value]);

  const handleFocus = () => {
    setFocus(true);
  };

  const sendOtp = async (e) => {
    if (!isEmailValid) {
      return;
    }
    setIsLoading(true);
    e.preventDefault();
    const response = await signInWithEmail(value);
    const { apiError: sendOtpError } = handleApiResponse(
      response,
      "Otp generation failure"
    );

    if (sendOtpError.length) {
      setErrorMessage("Unable to send OTP. Please try again.");
      setIsLoading(false);
      return;
    }
    setOtpSent(true);
    setTimerValue(60);
    setShowResend(false);
    setShowAlert(true);
    setIsLoading(false);
  };

  const resendOtpForSigin = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    setOtpValue("");
    const response = await resendOtp(value);
    const { apiError: resendOtpError } = handleApiResponse(
      response,
      "Resend Otp generation failure"
    );

    if (resendOtpError.length) {
      setErrorMessage("Unable to send OTP. Please try again.");
      setIsLoading(false);
      return;
    }
    setOtpSent(true);
    setTimerValue(60);
    setShowResend(false);
    setShowAlert(true);
    setIsLoading(false);
  };

  const verifyLoginOtp = async (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      const response = await verifyOtp({
        email: value,
        otp: parseInt(otpValue),
      });
      const { details, apiError: verifyOtpError } = handleApiResponse(
        response,
        "Otp verification failure"
      );
      if (verifyOtpError.length) {
        setErrorMessage("Incorrect OTP! Please enter valid OTP.");
        setShowError(true);
        setIsLoading(false);
        return;
      }

      const userDetailresponse = await authenticateUser(details.token);
      const { company, role, id, email, fullName, isSample } =
        userDetailresponse?.data?.entity;

      setErrorMessage("");
      setIsLoading(false);
      let authDetails = {
        ...details,
        company,
        role,
        id,
        email,
        fullName,
        isSample
      }
      if (Object.keys(redirectUrl).length > 0) {
        setAuth({ ...authDetails, isSample: false })
      } else {
        setAuth({ ...authDetails })
      }

      if (!company) {
        if (Object.keys(redirectUrl).length > 0)
          return router.push(`/db-view/details?from=${redirectUrl.from}`);
        localStorage.setItem("showSamplePopUp", true);
        router.push("/db-view/details");
      } else {
        if (Object.keys(redirectUrl).length > 0) return router.push(`${redirectUrl.from}`)
        router.push(`/db-view/${details.projectId}`);
      }
    } catch (err) {
      console.log("something went wrong", err);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (timerValue == 0) {
        setShowResend(true);
        return clearTimeout(timerId);
      }
      setTimerValue(timerValue - 1);
    }, 1000);
  }, [timerValue]);

  const onChangeOtpHandler = (e) => {
    const input = e.target.value.split(" - ").join("");
    const inputLength = input.length;
    const insertedValue = input[inputLength - 1];
    if (inputLength <= 4) {
      if (inputLength === 4) {
        setDisableVerify(false);
      } else {
        setDisableVerify(true);
      }
    } else if (inputLength === 4) {
      setDisableVerify(true);
    } else {
      return;
    }
    if (!isNaN(Number(insertedValue)) || inputLength === 0) {
      setOtpValue(input);
      const otp = input.split("").join(" - ");
      setOtpDisplayValue(otp);
    }
  };

  const resetLoginForm = () => {
    setValue("");
    setError("");
    setIsEmailValid(false);
    setDisableVerify(false);
    setOtpDisplayValue("");
    setOtpValue("");
    setOtpSent(false);
  };

  return (
    <div
      className={`w-[390px] bg-white border border-gray-200 rounded-lg shadow-sm px-8 py-9 relative ${className}`}
    >
      <div className="text-center absolute -top-16 left-1/2 transform -translate-x-1/2">
        <Image
          src={`${basePath}/images/logo-dbdaddy.svg`}
          width="100"
          height="39"
          alt="logo"
        />
      </div>
      <Heading className="text-gray-700 font-semibold text-center" type="h3">
        Sign In
      </Heading>
      <form className="pb-6 pt-9 relative">
        <Label className={"text-sm font-normal leading-5 text-gray-900"}>
          Email
        </Label>
        <div className={`w-[320px] h-11 relative mt-2 ${className}`}>
          <Input
            className={`w-full h-full border rounded-lg focus:outline-none placeholder-gray-500 text-sm ${hasFocus
              ? "bg-white border-purple-700 pl-4 text-gray-800"
              : `bg-gray-60 border border-gray-200 text-gray-70 ${!value ? "pl-12" : "pl-4"
              }`
              } ${isOtpSent && "cursor-not-allowed"}`}
            onFocus={handleFocus}
            onBlur={() => {
              setFocus(false);
            }}
            onChange={(e) => setValue(e.target.value)}
            placeholder={`${hasFocus ? "" : "Enter your email"}`}
            type="email"
            required={true}
            disabled={isOtpSent}
            value={value || ""}
          />
          {!hasFocus && !value && (
            <span className={"absolute top-3 left-4"}>
              <Image
                src={`${basePath}/images/sms.svg`}
                alt="email"
                width="18"
                height="18"
              />
            </span>
          )}
        </div>

        {isOtpSent && (
          <InputWithLabel
            className="mt-3"
            classForInput={`${focused
              ? error
                ? "bg-white border-red-400"
                : "bg-white border-purple-700"
              : "bg-gray-60 "
              }`}
            labelText="Verification code"
            name="otp"
            placeholder={focused ? "" : "Enter 4 digit code"}
            type="text"
            value={otpDisplayValue}
            varient="normal"
            handleFocus={() => {
              setFocused(true);
            }}
            handleBlur={() => {
              setFocused(false);
            }}
            // disabled={!isOtpSent}
            onChange={onChangeOtpHandler}
          />
        )}

        {errorMessage && (
          <div className="text-red-400 font-normal leading-5 text-xs pt-2">
            {errorMessage}
          </div>
        )}
        {isOtpSent && !showResend && (
          <Text className="text-gray-600 text-xxs text-right">
            Resend OTP in <span className="font-medium">{timerValue}s</span>
          </Text>
        )}
        {isOtpSent && showResend && (
          <span onClick={resendOtpForSigin}>
            <Text className="text-gray-600 text-xxs text-right hover:underline cursor-pointer">
              Resend OTP{" "}
            </Text>
          </span>
        )}
        {!isOtpSent && (
          <Button
            id="sendOtp"
            className={`w-full mt-6  hover:bg-primary-1000  ${isOtpSent && "bg-primary-100 text-primary-500 cursor-not-allowed"
              }`}
            iconShow={false}
            handleClick={isEmailValid && sendOtp}
            varient="secoundryBtn"
            disabled={isOtpSent || !value}
            isLoading={isLoading}
          >
            Send OTP
          </Button>
        )}

        {isOtpSent && (
          <Button
            id="verifyLoginOtp"
            className={`w-full mt-6  ${disableVerify &&
              "bg-primary-100 text-primary-500 cursor-not-allowed"
              }`}
            iconShow={false}
            handleClick={verifyLoginOtp}
            varient="secoundryBtn"
            isLoading={isLoading}
            disabled={disableVerify}
          >
            Verify
          </Button>
        )}
      </form>
      {isOtpSent ? (
        <div
          className="flex items-center text-center justify-center mt-3 cursor-pointer"
          onClick={resetLoginForm}
        >
          <span className="mr-1 flex items-center">
            <Image
              src={`${basePath}/images/arrow-left.svg`}
              width="16"
              height="16"
              alt="arrow-left"
            />
          </span>
          <div>
            <span className="text-gray-600 text-xs font-medium">
              Back to Sign In
            </span>
          </div>
        </div>
      ) : (
        <div className="border-t border-gray-200 relative">
          <span className="bg-white text-xs leading-4 font-medium absolute right-2/4 -top-2 px-3 transform translate-x-4">
            OR
          </span>
          <Anchor
            href={googleRedirectUrl}
            className="flex items-center justify-center border border-gray-300 w-full py-3 rounded-lg bg-gray-60 mt-6 text-gray-700 text-sm font-medium"
            id="signInWithGoogle"
          >
            <span className="mr-2 flex items-center">
              <Image
                src={`${basePath}/images/Google.svg`}
                width="24"
                height="24"
                alt="google"
              />
            </span>{" "}
            Sign In with Google
          </Anchor>
        </div>
      )}
    </div>
  );
};
Login.propTypes = {
  className: PropTypes.string,
  basePath: PropTypes.string,
  setShowAlert: PropTypes.func,
  setShowError: PropTypes.func,
};

export default Login;
