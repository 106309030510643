import Image from 'next/image';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Text from '../atoms/Text';
const Toaster = ({type, toasterImgSrc1, toasterImgAlt1, toasterHeading, imgSrc2 = '/images/x.svg', imgAlt2 = 'x', className, toasterHeadingClass = 'text-gray-800 font-medium ', basePath = process.env.NEXT_PUBLIC_BASE_PATH }) => {

  const toasterType = {
    success: 'rounded flex items-center mx-auto bg-green-600 pl-5 w-[278px] pr-3 h-[52px]',
    danzer: 'rounded flex items-center gap-x-2.5 mx-auto bg-red-400 pl-5 w-[154px] h-[52px]',
    incorrectOtp:'rounded-lg flex items-center justify-between m-auto bg-red-50 border border-red-400 px-[18px] w-[204px] h-[52px]',
    otpSuccess: 'flex items-center px-4 w-[295px] h-[50px] bg-white border border-gray-200 shadow-sm rounded-lg text-sm font-normal text-green-600 m-auto',
    success2:'flex items-center px-2.5 fit-content h-10 bg-white border border-gray-200 shadow-sm rounded-lg text-sm font-normal text-gray-600 m-auto',
    error:'flex fit-content items-center px-2.5 h-10 bg-white border border-gray-200 shadow-sm rounded-lg text-sm font-normal text-gray-600 m-auto'
  }
  const [hide, setHide] = useState(true);
  const handleClick = () => {
    setHide(false);
  }


  return (
   hide && <div className={`absolute left-0 right-0 z-50 mt-[10px] ${toasterType[type]} ${className}`}>
      <Image src={`${basePath}${toasterImgSrc1}`} alt={toasterImgAlt1} width="24" height="24" />
      <Text className={`${toasterHeadingClass}`}>{toasterHeading}</Text>
      <Image src={`${basePath}${imgSrc2}`} alt={`${imgAlt2}`} width="24" height="24" className='cursor-pointer' onClick={handleClick} />
    </div>
  )
}
Toaster.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  type: PropTypes.string,
  toasterImgSrc1: PropTypes.string,
  toasterImgAlt1: PropTypes.string,
  toasterHeading: PropTypes.string,
  imgSrc2: PropTypes.string,
  imgAlt2: PropTypes.string,
  className: PropTypes.string,
  toasterHeadingClass: PropTypes.string
}
export default Toaster