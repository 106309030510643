import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Login from '../components/Login/Login';
import Toaster from '../molecules/Toaster';

const UserLogin = ({ className, height = 'h-full', basePath = process.env.NEXT_PUBLIC_BASE_PATH }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowError(false);
    }, 2000);
  }, [showError]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  }, [showAlert]);

  return (
    <div className={`flex justify-center w-full h-full ${className}`}>
      {showAlert && <Toaster
        basePath=""
        className="bg-white"
        imgAlt2="close-2"
        imgSrc2={`${basePath}/images/Close-2.svg`}
        toasterHeading="OTP has been sent successfully"
        toasterHeadingClass="text-green-600 ml-1 mr-2 text-sm font-normal"
        toasterImgAlt1="close-circle"
        toasterImgSrc1={`${basePath}/images/alert-circle.svg`}
        type="otpSuccess"
      />
      }
      {showError && <Toaster
        basePath=""
        className=""
        imgAlt2="close-2"
        imgSrc2={`${basePath}/images/close-circle.svg`}
        toasterHeading="Incorrent OTP"
        toasterHeadingClass="ml-1 mr-3 text-sm font-normal"
        toasterImgAlt1="close-circle"
        toasterImgSrc1={`${basePath}/images/alert-circle-red.svg`}
        type="incorrectOtp"
      />
      }

      <div className='w-full h-full flex justify-center items-center'>
        <Login
          basePath={basePath}
          className=""
          setShowAlert={setShowAlert}
          setShowError={setShowError} />
      </div>
    </div>
  );
};

UserLogin.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string
};
export default UserLogin;
